.back{
  min-height: 100vh;
  background-image: url("../public/backk.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}
body{
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

}
.colorr{
  color: white !important;
  font-size: 19px;
}
.nav-color{
  background-color: rgba(55, 55, 54, 0.51);
  color: white !important;
}
